/* eslint-disable no-extend-native */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Root from './Root'
import './index.css'
import TestProvider from 'contexts/TestContext'
import ShortsProvider from 'contexts/ShortsContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

declare global {
  interface Array<T> {
    asClass: string
  }
}

Object.defineProperty(Array.prototype, 'asClass', {
  configurable: true,
  get() {
    return this.filter(Boolean).join(' ')
  },
})

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TestProvider>
        <ShortsProvider>
          <div className="py-10 hidden text-justify leading-10 p-8 space-x-3 space-y-4 border-4 border-green-400" />
          <Root />
        </ShortsProvider>
      </TestProvider>
    </BrowserRouter>
  </React.StrictMode>
)
