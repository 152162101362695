import { usePreviousTestsDB } from 'modules/previousTests/hook'
import { PreviousTestEntry } from 'modules/previousTests/types'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

const PreviousTestsPage: FC = () => {
  const db = usePreviousTestsDB()
  const [tests, setTests] = useState<PreviousTestEntry[]>()
  const [loading, setLoading] = useState(true)
  const navigate =useNavigate()

  useEffect(() => {
    db.getAll().then((tests) => {
      setTests(tests.sort((a, b) => new Date(b.taken_at).getTime() - new Date(a.taken_at).getTime()))
      setLoading(false)
    })
  }, [db])

  const onTest = (test: PreviousTestEntry) => {
    navigate(test.test_url, {state: {previous: test.id}})
  } 

  return (
    <div className="w-full h-full p-8 px-6 overflow-y-auto flex justify-center items-start pt-9">
      <div className="grid grid-cols-6 gap-4 text-kura font-bold text-center vertical-middle max-w-max mobile:grid-cols-3 mobileWidth:grid-cols-3 mobile:gap-6 mobileWidth:gap-6">
        {tests?.map((test) => (
          <div key={test.id} className="grid-cols-5 grid col-span-6 gap-3 mobile:grid-cols-3 mobileWidth:grid-cols-4 cursor-pointer select-none hover:opacity-[0.85] transition-all" onClick={() => onTest(test)}>
            <div className="bg-jajko p-2 px-3 rounded-lg col-span-3 uppercase">{test.test_name.split("-").at(0)?.trim()}</div>
            <div className="bg-yellow-200 p-2 rounded-lg col-span-1 flex items-center justify-center">{Math.floor((100 * (test.correct_answers / test.total_answers)))}%</div>
            <div className="bg-yellow-200 p-2 rounded-lg col-span-1 mobile:hidden mobileWidth:hidden">{new Date(test.taken_at).toLocaleDateString(undefined, {day: "2-digit", month: "2-digit", year: "numeric"}).replaceAll(". ", "/")}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PreviousTestsPage
