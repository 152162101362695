import useTestContext from 'contexts/TestContext/hook'
import { getQuestionNumber, getTestAsset } from 'helpers'
import { FC, useLayoutEffect } from 'react'
import Question from './Question'
import { useNavigate } from 'react-router'
import HTML from 'components/HTML'
import Audio from 'components/Audio'
import { Adsense } from '@ctrl/react-adsense'

const TestPage: FC = () => {
  const { data: test, started, end, ended } = useTestContext()
  const navigate = useNavigate()

  useLayoutEffect(() => {
    Array.from(document.body.getElementsByClassName('blank-answer')).forEach((el) => {
      el.innerHTML = '&emsp;&emsp;'
    })
  }, [test])

  useLayoutEffect(() => {
    const test = document.getElementById('test')
    if (!test) return
    const cb = () => {
      const test = document.getElementById('test')
      if (test) {
        const recordings = Array.from(test.getElementsByClassName('recording')).map((el) => el.firstChild as HTMLDivElement)
        const screenRect = document.body.getBoundingClientRect()
        const testTop = screenRect.top
        const testBottom = screenRect.bottom
        //const playable: HTMLAudioElement[] = []
        recordings.forEach((wrapper) => {
          if (!wrapper) return
          const recordingRect = wrapper.getBoundingClientRect()
          const recordingTop = recordingRect.top
          const recordingBottom = recordingRect.bottom
          if (recordingTop < testTop) {
            wrapper.getElementsByTagName('audio')[0]?.pause()
          } else if (recordingBottom > testBottom) {
            wrapper.getElementsByTagName('audio')[0]?.pause()
          } /* else {
            playable.push(wrapper.getElementsByTagName("audio")[0] as HTMLAudioElement)
          }

          const topOne = playable.reduce((prev, curr) => {
            if (curr?.getBoundingClientRect().top < prev?.getBoundingClientRect().top) {
              return curr
            }
            return prev
          }, playable[0])

          topOne?.play?.()

          playable.forEach((recording) => {
            if (recording !== topOne) {
              recording?.pause()
            }
          })*/
        })
      }
    }

    test.addEventListener('scroll', cb)

    return () => {
      test.removeEventListener('scroll', cb)
    }
  }, [started])

  return (
    <div
      id="test"
      className={['w-full h-full justify-center overflow-y-scroll transition-all bg-back overscroll-contain', started ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'].asClass}
    >
      <div className="flex flex-col space-y-5 pb-5 divide-y border-slate-200 pb-0 [&>*:not(:first-child)]:!border-t-0">
        {test?.recording && !ended && (
          <div className="w-full p-4 sticky top-0 z-[100] -mb-10">
            <Audio recording={test.recording} />
          </div>
        )}
        {test?.sections.map((section, i) => (
          <div key={`s_${i}`} className="flex flex-col space-y-5 divide-y border-slate-200 animate-fade-in pt-5">
            <h2 className="font-semibold uppercase text-2xl text-center select-none py-2 px-2">{section.name}</h2>
            {section?.recording && !ended && (
              <div className="w-full p-4 sticky top-0 z-[100]">
                <Audio recording={section.recording} />
              </div>
            )}
            {section.description && (
              <div className="w-full bg-white flex justify-center py-5 relative pb-10 px-5 border-b border-b-slate-200">
                <img className="max-w-max w-full" src={getTestAsset(section.description)} />
                <div className="absolute bg-back mix-blend-multiply inset-0" />
              </div>
            )}
            {section.htmlContent && <HTML content={section.htmlContent} />}
            {section.parts.map((part, j) => (
              <div key={`p_${i}_${j}`} className="flex flex-col space-y-2 items-center pt-5">
                {part?.recording && !ended && (
                  <div className="w-full p-4 sticky top-0 z-[100]">
                    <Audio recording={part.recording} />
                  </div>
                )}
                <h3 className="font-semibold uppercase text-2xl text-center select-none pt-2 pb-6 w-full border-b border-b-slate-200 px-2">{part.name}</h3>
                {part.description && (
                  <div className="w-full bg-white flex justify-center py-5 relative pb-10 px-5 border-b border-b-slate-200">
                    <img className="max-w-max w-full" src={getTestAsset(part.description)} />
                    <div className="absolute bg-back mix-blend-multiply inset-0" />
                  </div>
                )}
                {part.htmlContent && <HTML content={part.htmlContent} />}
                <div className="px-5 flex flex-col items-start w-full space-y-2 pt-8">
                  {part.questions.map((question, k) => {
                    const q = getQuestionNumber(test, i, j, k)
                    return (
                      <>
                        <Question question={question} id={`q_${i}_${j}_${k}`} key={`q_${i}_${j}_${k}`} number={q} showResult={ended} />
                        {q % 5 === 4 && <Adsense client="ca-pub-9576579674835300" slot="4250520675" style={{ display: 'block', textAlign: 'center' }} layout="in-article" format="fluid" />}
                      </>
                    )
                  })}
                </div>
              </div>
            ))}
          </div>
        ))}
        {ended ? (
          <div className="w-full flex justify-evenly mobileWidth:flex-col items-center py-4 gap-5">
            <button className="button-primary text-xl text-center w-max px-10 mobileWidth:mb-[86px]" onClick={() => navigate('/tests/cz')}>
              Späť na výber testov
            </button>
          </div>
        ) : (
          <div className="w-full flex justify-center py-5">
            <button className="button-primary bg-kura text-yellow-300 font-bold text-xl text-center w-max px-10" onClick={end}>
              Ukončiť test
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default TestPage
