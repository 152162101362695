import { FC } from "react";
import { IAnswered, IQuestion } from "types/tests";
import MultichoiceQuestion from "./MultiChoice";
import FreeResponseQuestion from "./FreeResponse";
import AnjQuestion from "./AnjQuestion";

interface props {
    id: string
    number: number
    question: IAnswered | IQuestion
    showResult?: boolean
}

const Question: FC<props> = ({ question, ...rest }) => {
    if (question.type?.startsWith("anj")) {
        return <AnjQuestion question={question} {...rest} />
    }
    switch (question.type) {
        case 'multi-choice':
            return <MultichoiceQuestion question={question} {...rest} />
        default:
            if (question.type) return <AnjQuestion question={question} {...rest} />
            return <FreeResponseQuestion question={question} {...rest} />
    }
}

export default Question