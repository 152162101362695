import { isCorrect } from 'helpers'
import { FC } from 'react'
import { IMultiChoiceAnswered, IMultiChoiceUnanswered } from 'types/tests/sjl'

interface props {
  id: string
  number: number
  question: IMultiChoiceAnswered | IMultiChoiceUnanswered
  showResult?: boolean
}

const MultichoiceQuestion: FC<props> = ({ id, number, question, showResult }) => {
  const correct = showResult && question.answered && isCorrect(question)

  const correctClass = showResult && 'text-green-600 border-green-600 border-2 bg-green-50'
  const incorrectClass = showResult && 'text-rose-600 border-rose-600 border-2 bg-rose-50'

  const numberClass = showResult
    ? correct
      ? 'ring-offset-2 ring-2 ring-green-600 rounded bg-green-600 w-8 shrink-0 text-center text-black select-none'
      : 'ring-offset-2 ring-2 ring-rose-600 rounded bg-rose-600 w-8 shrink-0 text-center text-black select-none'
    : 'ring-offset-2 ring-2 ring-jajko rounded bg-jajko w-8 shrink-0 text-center text-black select-none'

  return (
    <div
      style={{ borderLeft: !showResult ? undefined : correct ? '4px solid green' : '4px solid red' }}
      id={id}
      data-type="question"
            data-qtype={question.type}
data-number={number}
      className="flex flex-col w-full items-start space-x-2 px-5 justify-start pb-5"
    >
      <div className="flex items-start space-x-4 py-4">
        <span className={numberClass}>{number}</span>
        <span className="font-semibold -mt-0 select-none" dangerouslySetInnerHTML={{ __html: question.question }} />
      </div>
      {question.choices.map((choice: string, l: number) => (
        <div key={`${id}_${l}`} className="flex items-start mb-2 px-10 cursor-pointer">
          <input
            disabled={showResult}
            id={`${id}_${l}`}
            onInput={(e) => e.currentTarget.closest('[data-type="question"]')?.setAttribute('data-answer', e.currentTarget.checked ? e.currentTarget.value : '')}
            name={id}
            type="radio"
            className="peer disabled:hidden cursor-pointer h-4 w-4 mt-1 accent-kura shrink-0"
            value={l}
          />
          <label
            htmlFor={`${id}_${l}`}
            dangerouslySetInnerHTML={{ __html: choice }}
            className={
              [
                'peer-checked:font-bold select-none cursor-pointer rounded px-2',
                !showResult
                  ? "peer-checked:text-kura"
                  : correct && question.answer === l
                  ? correctClass
                  : !correct && question.answered && question.answer === l
                  ? incorrectClass
                  : !correct && (question.correctAnswer as any).includes(l)
                  ? [correctClass, "border-0"].asClass
                  : undefined,
              ].asClass
            }
          />
        </div>
      ))}
    </div>
  )
}

export default MultichoiceQuestion
