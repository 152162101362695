import { IndexedDBProps } from "react-indexed-db-hook";

export const indexedDB: IndexedDBProps = {
  name: "KuraMaturaDB-CZ",
  version: 2,
  objectStoresMeta: [
    {
      store: "previous_tests",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        { name: "taken_at", keypath: "taken_at", options: { unique: true } },
        { name: "time", keypath: "time", options: { unique: false } },
        { name: "test_name", keypath: "test_name", options: { unique: false } },
        { name: "test_url", keypath: "test_url", options: { unique: false } },
        { name: "correct_answers", keypath: "correct_answers", options: { unique: false } },
        { name: "total_answers", keypath: "total_answers", options: { unique: false } },
        { name: "test", keypath: "test", options: { unique: false } },
      ],
    },
  ],
};