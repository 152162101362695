import { useEffect, useState } from "react";
import isMobile from 'helpers/isMobile'

const useIsMobile = () => {
    const [mobile, setMobile] = useState(isMobile() || window.innerWidth < 1230);
    useEffect(() => {
        const cb = () => {
            setMobile(isMobile() || window.innerWidth < 1230);
        };
        window.addEventListener("resize", cb);
        return () => window.removeEventListener("resize", cb);
    }
    , []);
    
    return mobile;
};

export default useIsMobile;