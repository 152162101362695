import useIsMobile from 'hooks/useIsMobile'
import { FC, useEffect, useRef, useState } from 'react'

interface props {
  pick?: string
  hideDubky?: boolean
}

const Header: FC<props> = ({ pick, hideDubky: _hideDubky }) => {
  const [from, setFrom] = useState<string | null>(null)
  const [to, setTo] = useState<string | null>(null)
  const mobile = useIsMobile()

  const hideDubky = _hideDubky || mobile

  const wrapper = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTo((old) => {
      setFrom(old)
      wrapper.current?.classList.remove('animate-tp-header')
      return pick || null
    })
  }, [pick])

  useEffect(() => {
    if (!wrapper.current) return
    wrapper.current.classList.add('animate-tp-header')
    wrapper.current.onanimationend = (e) => {
      e.animationName === 'tp-header-d-tr' && setFrom(to)
    }
  }, [to])

  const titleClass = 'absolute text-[6.25rem] mobile:!text-[4rem] mobileWidth:text-[5rem] leading-[5rem] font-bold text-jajko-50 w-max font-semibold select-none'
  const pickClass = 'absolute text-[3.125rem] uppercase w-full text-kura leading-[3rem] font-bold text-center font-semibold select-none'

  const hiddenDubekTR = hideDubky ? '!opacity-0 !-rotate-45' : 'delay-[500ms]'
  const hiddenDubekTL = hideDubky ? '!opacity-0 !rotate-45' : 'delay-[500ms]'

  return (
    <div ref={wrapper} className={['relative overflow-visible transition-all', mobile ? "px-[3rem]" : "px-[6rem]"].asClass}>
      <div id="dubek_tp_tr" className={['h-1/2 aspect-square absolute right-0 top-0 origin-bottom-right transition-all duration-500', hiddenDubekTR].asClass}>
        <div className="relative w-full h-full overflow-hidden">
          <div className="h-[200%] aspect-square absolute bg-yellow-300 rounded-full right-0 -top-full translate-x-1/2" />
        </div>
      </div>
      <div id="dubek_tp_br" className={['h-1/2 aspect-square absolute right-0 bottom-0 origin-top-right transition-all duration-500', hiddenDubekTL].asClass}>
        <div className="relative w-full h-full overflow-hidden">
          <div className="h-[200%] aspect-square absolute bg-yellow-300 rounded-full right-0 top-0 translate-x-1/2" />
        </div>
      </div>
      <div id="tofrom" className="h-[6.25rem] max-w-[500px] flex items-center justify-center">
        <h1 id="to" className={!to ? titleClass : pickClass}>
          {to || 'SHORTS'}
        </h1>
        <h1 id="from" className={!from ? titleClass : pickClass}>
          {from}
        </h1>
        <h1 className={[titleClass, '!opacity-0 pointer-events-none w-max text-center relative left-0 top-0'].asClass}>SHORTS</h1>
      </div>
      <div id="dubek_tp_tl" className={['h-1/2 aspect-square absolute left-0 top-0 origin-bottom-left transition-all duration-500', hiddenDubekTL].asClass}>
        <div className="relative w-full h-full overflow-hidden">
          <div className="h-[200%] aspect-square absolute bg-yellow-300 rounded-full left-0 -top-full -translate-x-1/2" />
        </div>
      </div>
      <div id="dubek_tp_bl" className={['h-1/2 aspect-square absolute left-0 bottom-0 origin-top-left transition-all duration-500', hiddenDubekTR].asClass}>
        <div className="relative w-full h-full overflow-hidden">
          <div className="h-[200%] aspect-square absolute bg-yellow-300 rounded-full left-0 top-0 -translate-x-1/2" />
        </div>
      </div>
    </div>
  )
}

export default Header
