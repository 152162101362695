import { FC, useRef } from 'react'
import Header from './Header'
import { Outlet, useNavigate, useParams } from 'react-router'
import useAvailableShortsData from 'hooks/testSelect/useAvailableShortsData'

const ShortsPicker: FC = () => {
  const subject = useParams().subject
  const subjects = useAvailableShortsData()
  const navigate = useNavigate()
  const wrapper = useRef<HTMLDivElement>(null)

  const subjectHidden = !!subject

  const nothingShown = subjectHidden

  const toSubject = (subject: string) => {
    navigate(`/shorts/${subject}`)
  }

  return (
    <>
      <div className="absolute inset-0 z-[10]" style={subject ? {pointerEvents: "auto"} : {pointerEvents: "none"}}>
        <Outlet />
      </div>
      <div ref={wrapper} className={["flex transition-all flex-col pt-[calc(93px+1.5rem)] mobile:pt-[calc(10rem)] items-center h-0 overflow-visible animate-fade-in-delay", subjectHidden && "pointer-events-none"].asClass}>
        <div className={["transition-all", subjectHidden && "opacity-0"].asClass}>
          <Header pick={subject && " "} hideDubky={subjectHidden} />
        </div>
        <div
          ref={(e) => {
            if (!e) return
            if (!subjectHidden) {
              e.classList.add("in")
              e.classList.remove("out")
            } else {
              !nothingShown && e.classList.contains("in") && e.classList.remove("in")
              !e.classList.contains("out") && e.classList.add("out")
            }
          }}
          className={['flex flex-col gap-4 mt-8 items-center pb-8 animate-subj', subject && 'pointer-events-none'].asClass}
        >
          {
            Object.keys(subjects || {} as Record<string, string>).map((s, i) => (
              <button
                key={s}
                ref={(e) => {
                  if (!e) return
                  e.style.setProperty('--subj-index', `${i}`)
                }}
                className={['button-primary px-6 w-max'].asClass}
                onClick={() => toSubject(s)}
              >
                {subjects?.[s]}
              </button>
            ))}
        </div>
      </div>
    </>
  )
}

export default ShortsPicker
