import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router"

let nowFetched: string | null = null

const useYearData = () => {
  const params = useParams()
  const pathname = useLocation().pathname
  const pathSplit = pathname.split('/').slice(1)
  const pick = params.year
  const [lastPick, setLastPick] = useState<string | null>(null)
  const navigate = useNavigate()
  const [currentConfig, setCurrentConfig] = useState<number[] | null>(null)

  useEffect(() => {
    if (!pick) return
    setLastPick(pick)
  }, [pick])

  const currentConfigFile = useMemo(() => pathSplit.slice(0, 3).length === 3 && `/assets/${pathSplit.slice(0, 3).join('/')}/index.json`, [pathSplit])


  useEffect(() => {
    if (!currentConfigFile) {
      //setCurrentConfig(null)
      return
    }
    if (nowFetched === currentConfigFile && currentConfig) return
    fetch(currentConfigFile)
      .then((res) =>
        res.json().then((res) => {
          if (res.length === 1 && !pick) {
            navigate('./' + res[0], { relative: 'path' })
          }
          nowFetched = currentConfigFile
          setCurrentConfig(res)
        })
      )
      .catch((err) => setCurrentConfig(null))
  }, [currentConfigFile, currentConfig, pick])

  return {
    years: currentConfig,
    year: pick,
    lastYear: lastPick,
  }
}

export default useYearData