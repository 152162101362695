import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD7te9JCNNs_uu3tULYhN6N_A1G9Ss7TWk",
  authDomain: "kuramatura-7a9ba.firebaseapp.com",
  projectId: "kuramatura-7a9ba",
  storageBucket: "kuramatura-7a9ba.appspot.com",
  messagingSenderId: "443202482105",
  appId: "1:443202482105:web:55247260004ab119c51068",
  measurementId: "G-Z0DN1DX28T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const firebase = {
    app,
    analytics
}

export default firebase;