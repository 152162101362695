import useShortsContext from 'contexts/ShortsContext/hook'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

interface props {
  dubekClass?: string
  fontClass?: string
}

const StreakCounter: FC<props> = ({ dubekClass = 'bg-kura', fontClass = 'text-4xl text-kura font-semibold' }) => {
  const { data } = useShortsContext()
  const translateContainer = useRef<HTMLDivElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)
  const [prevStreak, setPrevStreak] = useState(0)

  const streak = useMemo(() => {
    let count = 0
    for (let i = (data?.answered.length || 0) - 1; i >= 0; i--) {
      if (data?.answered[i].correct) {
        count++
      } else {
        break
      }
    }
    return count
  }, [data?.answered])

  const animateToNext = useCallback(() => {
    if (!translateContainer.current) return
    const container = translateContainer.current

    const wrapperEl = wrapper.current
    const from = container.firstChild as HTMLSpanElement
    const to = container.lastChild as HTMLSpanElement

    wrapperEl?.classList.remove('animate-tp-header')

    from.animate(
      [
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
      ],
      {
        delay: 100,
        duration: 100,
        fill: 'both',
      }
    )
    if (!!streak) from.innerText = (streak - 1).toString()
    to.animate(
      [
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
      ],
      {
        delay: 900,
        duration: 100,
        fill: 'both',
      }
    )
    to.innerText = streak.toString()
    setTimeout(() => wrapperEl?.classList.add('animate-tp-header'), 10)

    const cleanUp = () => {
      container.removeEventListener('animationend', cleanUp, { capture: true })
      container.removeEventListener('animationcancel', cleanUp, { capture: true })

      from.innerText = streak.toString()

      setPrevStreak(streak)
    }

    container.addEventListener('animationend', cleanUp, { once: true, capture: true })
    container.addEventListener('animationcancel', cleanUp, { once: true })

    return () => {
      container?.removeEventListener('animationend', cleanUp, { capture: true })
      container?.removeEventListener('animationcancel', cleanUp, { capture: true })
    }
  }, [streak])

  useEffect(() => {
    animateToNext()
  }, [animateToNext])

  return (
    <div ref={wrapper} className="relative text-center w-full overflow-hidden mt-4">
      <div id="dubek_tp_tr" className="h-1/2 aspect-square absolute right-0 top-0 origin-bottom-right transition-all duration-500">
        <div className="relative w-full h-full overflow-hidden">
          <div className={['h-[200%] aspect-square absolute rounded-full right-0 -top-full translate-x-1/2', dubekClass].asClass} />
        </div>
      </div>
      <div id="dubek_tp_br" className="h-1/2 aspect-square absolute right-0 bottom-0 origin-top-right transition-all duration-500">
        <div className="relative w-full h-full overflow-hidden">
          <div className={['h-[200%] aspect-square absolute rounded-full right-0 top-0 translate-x-1/2', dubekClass].asClass} />
        </div>
      </div>
      <div ref={translateContainer} className="relative w-full h-max">
        <span className={['w-full text-center', fontClass].asClass}>{prevStreak}</span>
        <span className={['absolute top-0 left-0 text-center w-full opacity-0', fontClass].asClass}>{prevStreak}</span>
      </div>
      <div id="dubek_tp_tl" className={['h-1/2 aspect-square absolute left-0 top-0 origin-bottom-left transition-all duration-500'].asClass}>
        <div className="relative w-full h-full overflow-hidden">
          <div className={['h-[200%] aspect-square absolute rounded-full left-0 -top-full -translate-x-1/2', dubekClass].asClass} />
        </div>
      </div>
      <div id="dubek_tp_bl" className={['h-1/2 aspect-square absolute left-0 bottom-0 origin-top-left transition-all duration-500'].asClass}>
        <div className="relative w-full h-full overflow-hidden">
          <div className={['h-[200%] aspect-square absolute rounded-full left-0 top-0 -translate-x-1/2', dubekClass].asClass} />
        </div>
      </div>
    </div>
  )
}

export default StreakCounter
