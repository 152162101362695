import { useEffect, useState } from "react"

const configUri = '/assets/shorts/index.json'

const useAvailableShortsData = () => {
  const [currentConfig, setCurrentConfig] = useState<Record<string, string> | null>(null)


  useEffect(() => {
    fetch(configUri)
      .then((res) =>
        res.json().then((res) => {
          setCurrentConfig(res)
        })
      )
      .catch((err) => setCurrentConfig(null))
  }, [])

  return currentConfig
}

export default useAvailableShortsData