import { useEffect, useRef, useState } from "react";

const getSize = () => ({ w: window.screen.width, h: window.screen.height });

const useScreenSize = () => {
    const [size, setSize] = useState(getSize());
    const lastResize = useRef(getSize());
    const nextResizeTimeout = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        const cb = () => {
            lastResize.current = getSize();
            if (nextResizeTimeout.current) clearTimeout(nextResizeTimeout.current);
            nextResizeTimeout.current = setTimeout(() => {
                setSize(getSize());
            }, 10);
        };
        setSize(getSize());
        window.addEventListener("resize", cb);
        return () => window.removeEventListener("resize", cb);
    }
    , []);
    
    return size;
};

export default useScreenSize;