import { useIndexedDB as _useIndexedDB } from 'react-indexed-db-hook'
import { Key } from 'react-indexed-db-hook/lib/indexed-db'
import { v4 } from 'uuid'

export const useIndexedDB: <T = any>(objectStore: string) => {
  add: (value: Omit<T, "id">, key?: any) => Promise<string>
  getByID: (id: number | string) => Promise<T>
  getAll: () => Promise<T[]>
  update: (value: T, key?: any) => Promise<any>
  deleteRecord: (key: Key) => Promise<any>
  openCursor: (cursorCallback: (event: Event) => void, keyRange?: IDBKeyRange) => Promise<void>
  getByIndex: (indexName: string, key: any) => Promise<any>
  clear: () => Promise<any>
} = (objectStore: string) => {
  const {add: _add, ...returned} = _useIndexedDB(objectStore)

  const add = async <T = any>(value: T, key?: any) => {
    const id = v4()
    await _add({...value, id}, key)
    return id;
  }

  return {add, ...returned}
}
