import { createContext } from "react";
import { IShorts, IShortsQuestionAnswered, IShortsQuestionUnanswered } from "types/shorts";

interface IShortsContext {
    subjectName: string | null;
    data: IShorts | null;
    answer: (answer: number) => void;
    current: IShortsQuestionAnswered | IShortsQuestionUnanswered | null;
    next: () => void;
    picked: boolean;
}

const shortsContext = createContext<IShortsContext>({} as IShortsContext);

export default shortsContext;