import { createContext } from "react";
import { ITest } from "types/tests";

interface ITestContext {
    data: ITest | null;
    timeLeft: number | null;
    start: () => void;
    end: () => void;
    started: boolean;
    ended: boolean;
    answers: (string | number | null)[];
}

const testContext = createContext<ITestContext>({} as ITestContext);

export default testContext;