export const seededRandom = (seed: number) => {
    const x = Math.sin(seed) * 10000;
    return {seed, val: x - Math.floor(x)};
}

export const seededRandomize = <T extends any[] = unknown[]>(seed: number, array: T) => {
    const currentSeed = seed;
    return array.map((_, index) => {
        const {val} = seededRandom(currentSeed + index);
        return array[Math.floor(val * array.length)];
    }) as T;
}
