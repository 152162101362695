import isMobile from "helpers/isMobile";
import { useEffect, useState } from "react";

const useMousePos = () => {
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
    useEffect(() => {
        const mouseMove = (e: MouseEvent) => {
            setMousePos({ x: e.clientX, y: e.clientY });
        };
        const touchmove = (e: globalThis.TouchEvent) => {
            setMousePos({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        };
        if (isMobile()) {
            window.addEventListener("touchmove", touchmove);
            return () => window.removeEventListener("touchmove", touchmove);
        } else {
            window.addEventListener("mousemove", mouseMove);
            return () => window.removeEventListener("mousemove", mouseMove);
        }
    }

    , []);
    
    return mousePos;
};

export default useMousePos;