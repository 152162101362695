import { isCorrect } from 'helpers'
import { FC, useLayoutEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { IMissingChoiceAnswered, IMissingChoiceUnanswered } from 'types/tests/anj'

interface props {
  id: string
  number: number
  question: IMissingChoiceAnswered | IMissingChoiceUnanswered
  showResult?: boolean
}

const MissingChoicesQuestion: FC<props> = ({ id, number, question, showResult }) => {
  const correct = showResult && question.answered && isCorrect(question)
  const [value, setValue] = useState('')

  const [anchor, setAnchor] = useState<Element | null>(null)

  useLayoutEffect(() => {
    const el = document.getElementById(`anchor-${number}`)
    if (el) {
      setAnchor(el)
    } else {
      setAnchor(null)
    }
  }, [number])

  const incorrectClass = showResult && 'text-rose-600 rounded line-through bg-rose-50 px-1'

  return (
    <div id={id} data-type="question" data-answer={value}       data-qtype={question.type}
data-number={number} className="flex flex-col w-full items-start space-x-2 px-5 justify-start pb-5 hidden">
      {anchor &&
        createPortal(
          <span       data-qtype={question.type}
data-number={number}>
            <select
              onChange={(e) => setValue(e.target.value === question.choices.length.toString() ? '' : e.target.value)}
              className={[showResult ? 'hidden' : 'px-1 bg-jajko rounded ring-2 ring-offset-2 ring-jajko text-black mx-2'].asClass}
            >
              <option value={question.choices.length}>{''}</option>
              {question.choices.map((choice, i) => (
                <option className="px-1" key={i} value={i}>
                  {choice}
                </option>
              ))}
            </select>
            <span className={[showResult ? '' : 'hidden'].asClass}>
              {!correct && <span className={[incorrectClass, 'font-bold mr-2'].asClass}>{question.answered && question.answer ? question.choices[question.answer] : '    '}</span>}
              <span className="font-bold text-green-600 bg-green-100 px-1 rounded">{question.choices[question.correctAnswer]}</span>
            </span>
          </span>,
          anchor
        )}
      <input type="text" className="hidden" value={value} onChange={() => {}} />
    </div>
  )
}

export default MissingChoicesQuestion
