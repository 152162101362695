import { useState, useEffect } from 'react'

const useTimer = (end: number | null) => {
  const [timeLeft, setTimeLeft] = useState(end ? end - Date.now() : null)
  useEffect(() => {
    if (!end) return setTimeLeft(null)
    const interval = setInterval(() => {
      setTimeLeft(Math.max(end - Date.now(), 0))
    }, 100)
    return () => clearInterval(interval)
  }, [end])

  return timeLeft
}

export default useTimer
