import useTestContext from 'contexts/TestContext/hook'
import { FC } from 'react'
import QuestionList from '../QuestionList'
import { getQuestions, isCorrect } from 'helpers'

const formatTimeLeft = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  return `${minutes.toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`
}

interface props {
  hide: () => void
}

const TestSidebarContent: FC<props> = ({ hide }) => {
  const { data, ended, answers, timeLeft } = useTestContext()
  const qCount = answers?.length || 0
  const answered = answers?.reduce((acc: number, val) => acc + (val !== null ? 1 : 0), 0) || 0
  const correctAnswers = data ? getQuestions(data).map((q) => q.answered && isCorrect(q)) : []

  const correct = correctAnswers.reduce((acc, val) => acc + (val ? 1 : 0), 0)
  const finishedIn = data?.started && data.endTime && new Date(data.endTime).getTime() - new Date(data.startTime).getTime()

  return (
    <>
      <span className="text-lg bg-yellow-200 rounded-xl text-kura p-4 font-semibold w-full text-center leading-[130%] mt-8">
        {data?.name} - {data?.year}
      </span>

      {<div className="grow" />}
      {ended ? (
        <>
          <div className="grow" />
          <div className="flex space-x-2 mb-2 mt-4">
            <div className="flex flex-col select-none bg-yellow-200 text-kura rounded-xl grow shrink py-1 gap-1">
              <span className="font-medium text-xl w-full text-center leading-none">Trvalo</span>
              <span className="text-2xl font-semibold w-full text-center leading-none">{finishedIn && formatTimeLeft(finishedIn)}</span>
            </div>
            <div className="flex flex-col select-none bg-yellow-200 text-kura rounded-xl shrink grow py-1 gap-1">
              <span className="font-medium text-xl w-full text-center leading-none">Správně</span>
              <span className="text-2xl font-semibold w-full text-center leading-none">
                {correct.toString().padStart(qCount.toString().length, '0')}/{qCount}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="grow" />
          <div className="flex space-x-2 mb-2 mt-4">
            <div className="flex flex-col select-none bg-yellow-200 text-kura rounded-xl grow shrink py-1 gap-1">
              <span className="font-medium text-xl w-full text-center leading-none">Zůstává</span>
              <span className="text-2xl font-semibold w-full text-center leading-none">{timeLeft && formatTimeLeft(timeLeft)}</span>
            </div>
            <div className="flex flex-col select-none bg-yellow-200 text-kura rounded-xl shrink grow py-1 gap-1">
              <span className="font-medium text-xl w-full text-center leading-none">Vyplněno</span>
              <span className="text-2xl font-semibold w-full text-center leading-none">
                {answered.toString().padStart(answers.length.toString().length, '0')}/{answers.length}
              </span>
            </div>
          </div>
        </>
      )}
      <QuestionList hideSidebar={hide} />
    </>
  )
}

export default TestSidebarContent
