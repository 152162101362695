import { FC } from "react";
import { IAnswered, IQuestion } from "types/tests";
import TFNSQuestion from "./TFNS";
import OrderQuestion from "./OrderQuestion";
import MissingChoicesQuestion from "./MissingChoicesQuestion";
import MissingFreeQuestion from "./MissingFreeQuestion";
import MissingPickQuestion from "./MissingPickQuestion";
import TFSQuestion from "./TFS";
import FillFreeQuestion from "./FillFree";

interface props {
    id: string
    number: number
    question: IAnswered | IQuestion
    showResult?: boolean
}

const AnjQuestion: FC<props> = ({ question, ...rest }) => {
    switch (question.type) {
        case "tfns":
            return <TFNSQuestion question={question} {...rest} />
        case "order":
            return <OrderQuestion question={question} {...rest} />
        case "missing-choices":
            return <MissingChoicesQuestion question={question} {...rest} />
        case "missing-free":
            return <MissingFreeQuestion question={question} {...rest} />
        case "missing-pick":
            return <MissingPickQuestion question={question} {...rest} />
        case "tfs":
            return <TFSQuestion question={question} {...rest} />
        case "fill-free":
            return <FillFreeQuestion question={question} {...rest} />
        case 'multi-choice':
            return null
        default:
            return null
    }
}

export default AnjQuestion