import { FC } from "react";
import { Outlet, useLocation } from "react-router";
import Sidebar from "./Sidebar";
import DubkovePozadie from "components/DubkovePozadie";
import useTestContext from "contexts/TestContext/hook";
import useIsMobile from "hooks/useIsMobile";

const MainLayout: FC = () => {
    const {started} = useTestContext()
    const path = useLocation().pathname
    const mobile = useIsMobile()

    const hideDubky = path.startsWith("/previous") || (path.startsWith("/shorts") && mobile)

    return (
        <div className="w-screen h-full overflow-hidden flex bg-back">
            <Sidebar />
            <div className="h-full grow overflow-y-auto z-10 relative">
                <Outlet />
            </div>
            {!started && !hideDubky && <DubkovePozadie />}
        </div>
    )
} 

export default MainLayout;