import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import shortsContext from './context'
import { useLocation } from 'react-router'
import metaPixel from 'helpers/metaPixel'
import firebase from 'config/firebase'
import { logEvent } from 'firebase/analytics'
import { IShorts, IShortsConfig, IShortsQuestionAnswered, IShortsQuestionUnanswered } from 'types/shorts'
import { seededRandomize } from 'helpers/prg'
import useAvailableShortsData from 'hooks/testSelect/useAvailableShortsData'



const ShortsProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const availableShorts = useAvailableShortsData()
  const isShorts = location.pathname.startsWith("/shorts")
  const subject = location.pathname.split("/")[2]
  const picked = isShorts && subject ? true : false
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IShorts | null>(null)
  const [current, setCurrent] = useState<IShortsQuestionAnswered | IShortsQuestionUnanswered | null>(null)

  const loadShorts = useCallback(async () => {
    if (!picked) return setData(null)
    if (loading) return
    setLoading(false)
    return fetch(`/assets/shorts/${subject}/shorts.json`)
    .then((res) => res.json() as Promise<IShortsConfig>)
    .catch(() => null)
    .finally(() => setLoading(false))
  }, [picked, subject, loading])

  useEffect(() => {
    loadShorts().then((config) => {
      if (!config) return;
      const seed = Math.random() * 100
      const [current, ...randomized] = seededRandomize(seed, config.questions);
      setCurrent(current)
      setData({
        subject: config.subject,
        startTime: new Date().toISOString(),
        unanswered: randomized,
        answered: []
      })
    })
  }, [loadShorts, subject])

  const answer = useCallback((answer: number) => {
    let count: number;
    if (!current) return
    setData(old => {
      if (!old) return old
      count = old.answered.length + 1
      const answered = {...current, answer, correct: current.correctAnswer.includes(answer)}
      setCurrent(old => {
        if (!old) return old
        return ({
          ...old,
          answer,
          correct: old.correctAnswer.includes(answer)
        })
      })
      
      try {
        metaPixel.trackCustom("ShortsAnswer", {subject, answered: count as number})
        logEvent(firebase.analytics, 'shorts_answer', {subject, answered: count})
      } catch (e) {
        console.log(e)
      }

      return ({
        ...old,
        answered: [...old.answered, answered as IShortsQuestionAnswered]
      })
    })
  }, [subject, current])

  const next = useCallback(() => {
    setData(old => {
      if (!old) return old
      let [nextCurrent, ...newUnanswered] = old.unanswered;
      if (!newUnanswered.length) {
        const seed = Math.random() * 100
        const randomized = seededRandomize(seed, old.answered);
        newUnanswered = randomized.map(({answer: _, correct: __, ...unanswered}) => unanswered)
      }
      setCurrent(nextCurrent)
      return ({
        ...old,
        unanswered: newUnanswered
      })
    })
  }, [])

  const subjectName = subject && availableShorts ? availableShorts[subject] : null

  return <shortsContext.Provider value={{ subjectName, data, picked, current, answer, next }}>{children}</shortsContext.Provider>
}

export default ShortsProvider
