import { getTestAsset } from 'helpers'
import { FC, Fragment, createElement, useMemo } from 'react'

interface props {
  content: string | string[]
}

const HTML: FC<props> = ({ content }) => {
  const elements = useMemo(() => {
    const div = document.createElement('div')
    div.innerHTML = typeof content === 'string' ? content : content.join('')

    const reactElements = Array.from(div.children).map((el) => {
      if (el.tagName.toLowerCase() === 'img') {
        return (
          <div className="w-full bg-white flex justify-center py-5 relative pb-10 px-5 border-b border-b-slate-200">
            <img className="max-w-max w-full" src={getTestAsset((el as any)?.src.split('/').pop())} />
            <div className="absolute bg-back mix-blend-multiply inset-0" />
          </div>
        )
      } else {
        const ReactEl = createElement(el.tagName.toLowerCase(), {
          dangerouslySetInnerHTML: { __html: el.innerHTML },
          className: [el.className, 'font-medium'].asClass,
        })
        return ReactEl
      }
    })

    return reactElements
  }, [content])

  return (
    <>
      {elements.map((el, i) => {
        return <Fragment key={i}>{el}</Fragment>
      })}
    </>
  )
}

export default HTML
