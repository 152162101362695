import { IAnswered, IQuestion, ITest, ITestPartNotStarted, ITestPartStarted, ITestSectionNotStarted, ITestSectionStarted } from 'types/tests'

export const getTestAsset = (name: string) => {
  console.log(name);
  return `/assets/${window.location.pathname.split('/').slice(1).join('/')}/assets/${name}`
}

export const getQuestions = (test: ITest) => {
  return test.sections.flatMap((section) => section.parts.flatMap((part) => part.questions as (IQuestion | IAnswered)[]))
}

export const countQuestions = (test: ITest) => {
  return getQuestions(test).length
}

export const findParentQuestionOfInput = (input: HTMLInputElement) => {
  return input.closest('[data-type="question"]') as HTMLDivElement
}

export const getQuestionNumber = (test: ITest, s: number, p: number, q: number) => {
  let i = 0;
  (test.sections as (ITestSectionNotStarted | ITestSectionStarted)[]).every((section, _s) => {
    if (_s > s) {
      return false
    }
    (section.parts as (ITestPartNotStarted | ITestPartStarted)[]).every((part, _p) => {
      if (_s===s && _p > p) {
        return false
      }
      (part.questions as (IQuestion | IAnswered)[]).every((_, _q) => {
        if (_p === p && _s === s && _q > q) {
          return false
        }
        i++
        return true
      })
      return true
    })
    return true
  })
  return i
}

export const isCorrect = (question: IAnswered) => {
  switch (question.type) {
    case "multi-choice":
      return question.correctAnswer.includes(question.answer as number)
    case "tfs":
      return question.correctAnswer === question.answer
    case "tfns":
      return question.answer !== null && question.correctAnswer.includes(question.answer)
    case "fill-free":
      return question.correctAnswer.includes(question.answer as string)
    case "missing-choices":
      return question.correctAnswer.toString() === question.answer?.toString()
    case "missing-free":
      return question.correctAnswer.map((answer) => answer.toLowerCase()).includes(question.answer?.toLowerCase() as string)
    case "missing-pick":
      return question.correctAnswer === (question.answer === null ? null : parseInt(question.answer, 10))
    case "order":
      return question.correctAnswer.toLowerCase() === question.answer?.toLowerCase()
    default: 
      let correctAnswers = question.correctAnswer
      if (question.notCaseSensitive) {
        correctAnswers = correctAnswers.map((answer) => answer.toLowerCase())
        question.answer = (question.answer || "").toLowerCase()
      }
      return correctAnswers.includes(question.answer as string)
  }
}