import { Kura, Kuriatko } from 'assets'
import StreakCounter from 'components/StreakCounter'
import useShortsContext from 'contexts/ShortsContext/hook'
import { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { IShortsQuestionAnswered } from 'types/shorts'

const ShortsPage: FC = () => {
  const { data, current, answer, next, subjectName } = useShortsContext()
  const [kuriatkoPos, setKuriatkoPos] = useState<number>()
  const [secondKuriatkoPos, setSecondKuriatkoPos] = useState<number>()
  const scrollRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const onMouseMove = (e: MouseEvent<HTMLButtonElement>) => {
    // @ts-ignore
    if (current.answer !== undefined) return
    const el = e.currentTarget
    setKuriatkoPos(el.offsetTop + el.clientHeight + 8)
  }

  const onMouseMoveSecond = (e: MouseEvent<HTMLButtonElement>) => {
    const el = e.currentTarget
    setSecondKuriatkoPos(el.offsetTop + el.clientHeight)
  }

  // @ts-ignore
  const currentAnswered = current?.answer !== undefined ? (current as IShortsQuestionAnswered) : null

  const correctClass = 'border-4 border-green-400'
  const incorrectClass = 'border-4 border-red-400'
  const correctNotSelectedClass = 'border-4 border-green-400'

  useEffect(() => {
    if (!currentAnswered) scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    else scrollRef.current?.scrollTo({ top: scrollRef.current.scrollHeight, behavior: 'smooth' })
  }, [currentAnswered])

  if (!data) return null

  return (
    <div className="animate-fade-in flex flex-col h-full items-center z-100 overflow-hidden">
      <div className="hidden mobile:flex mobileWidth:flex py-4 flex flex-col items-center w-full bg-kura top-0 pl-4 text-center">
        <span className="text-yellow-300">{subjectName}</span>
        <div className="w-[7rem] -mt-2">
          <StreakCounter dubekClass="bg-yellow-300" fontClass="text-yellow-300 text-4xl font-semibold" />
        </div>
      </div>
      <div ref={scrollRef} className="grow w-full overflow-y-auto flex flex-col items-center ">
        <div className="flex flex-col w-full max-w-[720px] mobile:max-w-full mobileWidth:max-w-full px-8 gap-8 py-10 mobile:pt-4 mobileWidth:pt-4 animate-subj in">
          <div
            className="flex items-end max-w-[80%] gap-4"
            ref={(e) => {
              if (!e) return
              e.style.setProperty('--subj-index', '0')
            }}
          >
            <Kura className="w-12 h-12 shrink-0" />
            <span dangerouslySetInnerHTML={{ __html: current?.question || '' }} className="text-base bg-yellow-200 rounded-xl text-kura p-4 font-semibold w-max max-w-full text-left mt-8" />
          </div>
          <div
            ref={(e) => {
              if (!e) return
              e.style.setProperty('--subj-index', '3')
            }}
            className="self-end flex flex-col items-end pr-16 max-w-[80%] relative gap-4"
          >
            {current?.choices.map((c, i) => (
              <button
                onMouseMove={onMouseMove}
                dangerouslySetInnerHTML={{ __html: c || '' }}
                key={current.question + i}
                className={
                  [
                    'button-primary max-w-max !p-2 !py-1 border-4',
                    currentAnswered && 'pointer-events-none',
                    currentAnswered ? (currentAnswered?.answer === i ? (currentAnswered?.correct ? correctClass : incorrectClass) : current.correctAnswer.includes(i) ? correctNotSelectedClass : 'border-jajko') : "border-jajko",
                  ].asClass
                }
                onClick={() => answer(i)}
              />
            ))}
            <Kuriatko
              className="w-12 h-12 shrink-0 absolute right-0 transition-transform"
              style={{ transform: kuriatkoPos !== undefined ? `translate3d(0px,calc(${kuriatkoPos}px - 100%),0px)` : undefined }}
            />
          </div>
          {currentAnswered && (
            <>
              <div
                ref={(e) => {
                  if (!e) return
                  e.style.setProperty('--subj-index', '0')
                }}
                className="flex items-end max-w-[80%] gap-4"
              >
                <Kura className="w-12 h-12 shrink-0" />
                <span dangerouslySetInnerHTML={{ __html: current?.explanation || (currentAnswered?.correct ? "Správně" : "Nesprávně") }} className="text-base bg-yellow-200 rounded-xl text-kura p-4 font-semibold w-max max-w-full text-left" />
              </div>
              <div
                ref={(e) => {
                  if (!e) return
                  e.style.setProperty('--subj-index', '3')
                }}
                className="self-end flex flex-col items-end pr-16 max-w-[80%] relative gap-4"
              >
                <button onMouseMove={onMouseMoveSecond} className="button-primary bg-kura text-yellow-300 max-w-max px-4" onClick={next}>
                  Další Otázka
                </button>
                <button onMouseMove={onMouseMoveSecond} className="button-primary bg-kura text-yellow-300 max-w-max px-4" onClick={() => navigate("/shorts")}>
                  Konec
                </button>
                <Kuriatko
                  className="w-12 h-12 shrink-0 absolute right-0 top-0 transition-transform"
                  style={{ transform: secondKuriatkoPos !== undefined ? `translate3d(0px,calc(${secondKuriatkoPos}px - 100%),0px)` : undefined }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ShortsPage
